import * as React from "react";
import {floodActions} from "../../../../store/slice/flood";
import {useDispatch, useSelector} from "react-redux";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import DASlider from "./DASlider";
import {AppAPIs} from "../../../../Api";
import {useRef, useState, useEffect} from "react";
import GaugeInfoTable, {IGaugeInfo, SliderComponentRef} from "../../GaugeInfoTable";
import {Button, Slider} from "@mui/material";
import OverlayVectorLayer from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";
import DrmViewModel from "../../../view_models/DrmViewModel";
import {FloodInfoTable} from "../FloodInfoTable";
import {fluvialFloodLayerUUID} from "./FluvialFloodInputs";

interface IProps {
    mapVM: MapVM;
    setDialogActions: any;
}

interface IForecast {
    date: string;
    total_precipitation: number;
    total_snowfall: number;
    precipitation_hours: number;
}

const DistrictFloodInputs = (props: IProps) => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [prForecast, setPrForecast] = useState<IForecast[]>([]);
    const [gaugeInfos, setGaugeInfos] = useState<IGaugeInfo[]>([]);
    const [prRp, setPrRp] = useState([])
    const aoi_key = useSelector((state: any) => state.flood.aoi_key);
    const displayName = useSelector((state: any) => state.flood.displayName);
    const prValue = useSelector((state: any) => state.flood.selectedPRValue);
    const gaugesInput = useSelector((state: any) => state.flood.gaugesInput);
    const gauge1Ref: React.MutableRefObject<SliderComponentRef> = useRef<SliderComponentRef>();
    const gauge2Ref: React.MutableRefObject<SliderComponentRef> = useRef<SliderComponentRef>();
    const [maxPrSliderValue, setMaxPrSliderValue] = useState<number>(0);
    const [minPrSliderValue, setMinPrSliderValue] = useState<number>(0);
    const [sliderPrValue, setSliderPrValue] = useState<number>(0);
    const api = props.mapVM.getApi();

    const handleFloodInundationRequest = () => {
        if (loading) return;
        setLoading(true);

        const gauge1Value = gauge1Ref.current?.getValue() || -1;
        const gauge2Value = gauge2Ref.current?.getValue() || -1;
        dispatch(floodActions.setGaugesInput([gauge1Value, gauge2Value]));

        if (gauge1Value < gaugeInfos[0]?.medium) {
            props.mapVM.getSnackbarRef()?.current?.show(
                "To calculate flood flow should be greater than " + gaugeInfos[0]?.medium + " on " + gaugeInfos[0]?.gauge_name + " gauge"
            );
            setLoading(false);
        } else if (gauge2Value < gaugeInfos[1]?.medium) {
            props.mapVM.getSnackbarRef()?.current?.show(
                "To calculate flood flow should be greater than " + gaugeInfos[1]?.medium + " on " + gaugeInfos[1]?.gauge_name + " gauge"
            );
            setLoading(false);
        } else {
            props.mapVM.getMapLoadingRef()?.current?.openIsLoading();
            api.get(AppAPIs.FF_DISTRICT_FLOOD_INUNDATION, {
                district_id: aoi_key,
                pr_val: prValue,
                up_val: gauge1Value,
                down_val: gauge2Value
            }).then((payload) => {
                if (payload) {
                    let floodLayer: OverlayVectorLayer;
                    if (!(fluvialFloodLayerUUID in props.mapVM.overlayLayers)) {
                        floodLayer = DrmViewModel.createFloodLayer(props.mapVM, fluvialFloodLayerUUID);
                    } else {
                        floodLayer = props.mapVM.overlayLayers[fluvialFloodLayerUUID] as OverlayVectorLayer;
                        const source = floodLayer.olLayer.getSource();
                        source.clear();
                        source.refresh();
                    }

                    floodLayer.addGeojsonFeature(payload.geojson, true);
                    floodLayer.zoomToFeatures();
                    dispatch(floodActions.setFloodMapID(payload.map_id));

                    const rightDrawer = props.mapVM.getRightDrawerRef();
                    const features = floodLayer.getFeatures();
                    const affectedPopulation = features.reduce((accumulator: number, feature) => {
                        const properties = feature.getProperties();
                        return (accumulator + parseFloat(properties["population"]));
                    }, 0);
                    const contents = (
                        <React.Fragment>
                            <h3>{displayName} Flood Susceptibility</h3>
                            <br/>
                            <FloodInfoTable
                                mapVM={props.mapVM}
                                affectedPopulation={affectedPopulation}
                            />
                        </React.Fragment>
                    );

                    rightDrawer?.current?.addContents("Flood Impact", contents);
                    rightDrawer?.current?.openDrawer();
                }
            }).catch((error) => {
                console.error("result failed with error:", error);
                props.mapVM.getSnackbarRef()?.current?.show("Failed to retrieve flood data.");
            }).finally(() => {
                props.mapVM.getMapLoadingRef()?.current?.closeIsLoading();
                dispatch(floodActions.closeDialog());
                setLoading(false);
            });
        }
    };

    useEffect(() => {
        api.get(AppAPIs.FF_DISTRICT_FORECAST, {district_id: aoi_key})
            .then((payload) => {
                if (payload) {
                    // console.log("forecast", payload)
                    setPrRp(payload.rp)
                    let maxValue = Math.max(...payload.rp.map(item => item.max_value));

                    setPrForecast(payload.forecast);
                    const maxTotalPrecipitation = payload.forecast.reduce((max, forecast) => {
                        return forecast.total_precipitation > max ? forecast.total_precipitation : max;
                    }, 0);
                    setSliderPrValue(maxTotalPrecipitation)

                    if (maxValue < maxTotalPrecipitation)
                        maxValue = maxTotalPrecipitation

                    setMaxPrSliderValue(maxValue);

                    setGaugeInfos(payload.gaugeInfo);
                    props.setDialogActions(
                        <Button onClick={handleFloodInundationRequest}>
                            Calculate Flood
                        </Button>
                    );
                }
            });
    }, []);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue;
        setSliderPrValue(value);
    };

    return (
        <React.Fragment>
            {gaugeInfos.length > 0 ? gaugeInfos.map((gi, index) => (
                <GaugeInfoTable
                    key={"gauge-info-" + index}
                    ref={index === 0 ? gauge1Ref : gauge2Ref}
                    gaugeInfo={gi}
                    sliderValue={gaugesInput && gaugesInput[index]}
                />
            )) : <h4 style={{color: "red"}}>No Gauge Available</h4>}
            <div style={{overflowX: "auto", padding: "10px"}}>
                <h3>Select Precipitation Forecast</h3>
                <div style={{padding: "10px"}}>
                    <Slider
                        sx={{width: "100%"}}
                        value={sliderPrValue}
                        onChange={handleSliderChange}
                        aria-labelledby="slider-label"
                        valueLabelDisplay="auto"
                        step={(maxPrSliderValue - minPrSliderValue) / 100}
                        min={minPrSliderValue}
                        max={maxPrSliderValue}
                    />
                </div>
                {prRp.length > 0 && (
                    <div style={{overflowX: "auto", padding: "10px"}}>
                        <table className={"flow-table"}>
                            <thead>
                            <tr>
                                <th colSpan={7}><h3>Precipitation Return Period</h3></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                {prRp.map((data) => (
                                    <th key={`header-${data.return_period}`}>{`${data.return_period} years`}</th>
                                ))}
                            </tr>
                            <tr>
                                {prRp.map((data) => (
                                    <td key={`max-value-${data.return_period}`}>{data.max_value.toFixed(2)}</td>
                                ))}
                            </tr>
                            </tbody>
                        </table>
                    </div>
                )}
                {prForecast.length > 0 && (
                    <div style={{overflowX: "auto", padding: "10px"}}>
                        <table className={"flow-table"}>
                            <thead>
                            <tr>
                                <th colSpan={4}><h3>Precipitation Forecast</h3></th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <th>Date</th>
                                <th>Rain (mm/day)</th>
                                <th>Snow (mm/day)</th>
                                <th>Precipitation Hours</th>
                            </tr>
                            {prForecast.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.date}</td>
                                    <td>{item.total_precipitation.toFixed(2)}</td>
                                    <td>{item.total_snowfall.toFixed(2)}</td>
                                    <td>{item.precipitation_hours.toFixed(2)}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
};

export default DistrictFloodInputs;
