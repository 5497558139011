import * as React from "react";


const Drought = () => {
    return (
        <React.Fragment>
            Welcome to Drought
        </React.Fragment>
    )
}

export default Drought
