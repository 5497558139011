import React, {useRef, useState} from "react";
import {Button, Dialog, DialogTitle, DialogContent, DialogActions, Menu, MenuItem, TextField} from "@mui/material";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import AOISelector from "./AOISelector";
import {AppAPIs} from "../../../Api";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef, loadingRef} from "../../../App";
import FluvialFloodInputs from "./inputs/FluvialFloodInputs";
import PluvialFloodInputs from "./inputs/PluvialFloodInputs";
import GoogleEarthEngineButton, {addGEELayer} from "../common/GoogleEarthEngineButton";
import {useDispatch, useSelector} from "react-redux";
import {floodActions} from "../../../store/slice/flood";
import DistrictFloodInputs from "./inputs/DistrictFloodInputs";
import ZoomToPOIDialog from "../utilities/ZoomToPOIDialog";
import RiskAssessmentRequest from "../utilities/RiskAssessmentRequest";

interface IProps {
    mapVM: MapVM;
}

const FloodToolbar = (props: IProps) => {
    const aoiSelectorRef = useRef(null);
    const api = new MapApi(appSnackbarRef);
    const dialogOpen = useSelector((state: any) => state.flood.isDialogOpen)
    const dispatch = useDispatch()
    const floodType = useSelector((state: any) => state.flood.floodType)
    const aoi_key = useSelector((state: any) => state.flood.aoi_key);
    const displayName = useSelector((state: any) => state.flood.displayName);
    const [dialogActions, setDialogActions] = useState<React.ReactNode>(null);

    // Utilities button
    const [anchorUtilitiesEl, setAnchorUtilitiesEl] = useState(null);
    const openUtilities = Boolean(anchorUtilitiesEl);
    const poiDialogRef = useRef<{ openDialog: () => void }>(null);

    const handleClick = (event) => {
        setAnchorUtilitiesEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorUtilitiesEl(null);
    }
    const handleZoomToAOI = () => {
        if (aoiSelectorRef.current) {
            loadingRef?.current?.openLoader();
            const {floodType, aoi} = aoiSelectorRef.current;
            // console.log("Aoi", aoi)
            if (aoi) {
                if (floodType === "fluvial") {
                    api.get(AppAPIs.FF_REACH_GEOM, {reach_name: aoi.id}).then((payload) => {
                        if (payload) {
                            const selectionLayer = props.mapVM.getSelectionLayer();
                            selectionLayer.addGeoJson2Selection(payload);
                            selectionLayer.zoomToSelection();
                            loadingRef?.current?.closeLoader();
                        } else {
                            alert("No data exists")
                        }

                    });
                } else if (floodType === "pluvial") {
                    api.get(AppAPIs.FF_CATCHMENT_GEOM, {catchment_id: aoi.id}).then((payload) => {
                        if (payload) {
                            const selectionLayer = props.mapVM.getSelectionLayer();
                            selectionLayer.addGeoJson2Selection(payload);
                            selectionLayer.zoomToSelection();
                            loadingRef?.current?.closeLoader();
                        } else {
                            alert("No data exists")
                        }
                    });
                } else if (floodType == "district") {
                    api.get(AppAPIs.FF_DISTRICT_GEOM, {district_id: aoi.id}).then((payload) => {
                        if (payload) {
                            const selectionLayer = props.mapVM.getSelectionLayer();
                            selectionLayer.addGeoJson2Selection(payload);
                            selectionLayer.zoomToSelection();
                            loadingRef?.current?.closeLoader();
                        }
                    }).catch((e) => {
                        console.error(e)
                    })
                }
            } else {
                props.mapVM.getSnackbarRef()?.current?.show("Please select area of interest");
                loadingRef?.current?.closeLoader();
            }
        }
    }
    const getDialogContents = () => {
        switch (floodType) {
            case "fluvial":
                return <FluvialFloodInputs
                    key={"fluvial-flood-Inputs"}
                    reachName={aoi_key}
                    mapVM={props.mapVM}
                    zoomToAOI={handleZoomToAOI}
                    setDialogActions={(action) => setDialogActions(action)}
                />
            case "pluvial":
                return <PluvialFloodInputs
                    key={"pluvial-flood-inputs"}
                    mapVM={props.mapVM}
                    setDialogActions={(action) => setDialogActions(action)}
                />
            case "district" :
                return <DistrictFloodInputs
                    key={"district-flood-inputs"}
                    mapVM={props.mapVM}
                    setDialogActions={(action) => setDialogActions(action)}
                />
        }
    }
    const handleInputParameters = () => {

        if (aoiSelectorRef.current) {
            const {floodType, aoi} = aoiSelectorRef.current;
            dispatch(floodActions.setDisplayName(aoi.name))
            dispatch(floodActions.openDialog())
            dispatch(floodActions.setParams({floodType: floodType, aoi_key: aoi.id}))
        }

    }
    const handleOnDialogClose = () => {
        dispatch(floodActions.closeDialog())
    }

    const handleFloodCalculator = () => {
        dispatch(floodActions.resetData())
        addGEELayer("water_level", props.mapVM)
        // props.mapVM.getRightDrawerRef()?.current?.openDrawer();
        if (props.mapVM?.getMapPanelRef()?.current?.isBottomDrawerOpen()) {
            props.mapVM?.getMapPanelRef()?.current?.closeBottomDrawer();
        }


        props.mapVM.getRightDrawerRef()?.current?.addContents('Flood Inputs',
            <React.Fragment>
                <AOISelector key={"aoiSelector"} mapVM={props.mapVM} ref={aoiSelectorRef}/>
                <br/>
                <Button key={"zoomButton"} onClick={handleZoomToAOI}>Zoom to AOI</Button>
                <Button key={"inputParams"} onClick={handleInputParameters}>Input Parameters</Button>
            </React.Fragment>
        );
        props.mapVM.getRightDrawerRef()?.current?.openDrawer();
    }
    const handleDailyReport = () => {
        alert("Working...")
    }

    const getDialogTitle = () => {
        switch (floodType) {
            case "fluvial":
                return "Fluvial Flood Input"
            case "pluvial":
                return displayName + " Pluvial Flood Input"
            case "district":
                return "District " + displayName + " Flood Inputs"
        }
    }
    const handleZoomToPOI = () => {
        poiDialogRef?.current && poiDialogRef.current?.openDialog();
    };
    const handleFloodAssessment = () =>{
        const rightDrawer = props?.mapVM?.getRightDrawerRef().current;
        rightDrawer?.addContents("Area of Interest", <RiskAssessmentRequest mapVM={props.mapVM }/>)
        rightDrawer?.openDrawer()
    }
    return (
        <>
            <GoogleEarthEngineButton mapVM={props.mapVM}/>
            &nbsp;&nbsp;&nbsp;
            {/*<Button variant={"contained"} sx={{color: "white"}} onClick={handleFloodCalculator}>Flood*/}
            {/*    Calculator</Button>*/}
            <div>
                <Button
                    variant="contained"
                    sx={{color: "white"}}
                    onClick={handleClick}
                >
                    Utilities
                </Button>
                <Menu
                    anchorEl={anchorUtilitiesEl}
                    open={openUtilities}
                    onClose={handleClose}
                >
                    <MenuItem onClick={handleFloodCalculator}>Flood Calculator</MenuItem>
                    {/*<MenuItem onClick={handleDailyReport}>Daily Report</MenuItem>*/}
                    <MenuItem onClick={handleFloodAssessment}>16-days Flood Assessment</MenuItem>
                    <MenuItem onClick={handleZoomToPOI}>Zoom To POI</MenuItem>
                </Menu>
            </div>
            {props.mapVM && <ZoomToPOIDialog ref={poiDialogRef} mapVM={props.mapVM}/>}
            <Dialog open={dialogOpen} onClose={handleOnDialogClose} fullWidth maxWidth="md">
                <DialogTitle>{floodType && getDialogTitle()}</DialogTitle>
                <DialogContent>
                    <React.Fragment>
                        {floodType && getDialogContents()}
                    </React.Fragment>
                </DialogContent>
                <DialogActions>
                    {dialogActions}
                    <Button onClick={handleOnDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default FloodToolbar;
