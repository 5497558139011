import {createSlice} from "@reduxjs/toolkit";

export const AuthSlice = createSlice({
    name: "auth",
    initialState: {
        isAuthenticated: false,
        userInfo: {},
        accessToken: "",
        refreshToken: ""
    },
    reducers: {
        isUserAuthenticated:   (state, action) => {
            state.isAuthenticated = action.payload
        },
        setUserInfo: (state, action) => {
            state.userInfo = action.payload
            localStorage.setItem("userInfo", action.payload)
        },
        setTokens:(state, action)=>{
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
        },
        login: (state,action) =>{
            const userInfo = localStorage.getItem("userInfo")
            if(userInfo){
                state.userInfo = userInfo
                state.accessToken = userInfo.accessToken
                state.refreshToken = userInfo.refreshToken
                state.isAuthenticated = true
            }
        },
        logout:(state, action)=>{
            state.isAuthenticated = false
            state.userInfo ={}
            state.accessToken = ""
            state.refreshToken = ""
            localStorage.removeItem("userInfo")
        }
    }
});

export const authActions = AuthSlice.actions;
export const authReducer = AuthSlice.reducer;
