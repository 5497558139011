import * as React from "react";
import "./table-style.css";
import { Typography, Slider } from "@mui/material";

export interface IGaugeInfo {
    id: number;
    gauge_name: string;
    inflow: number;
    outflow: number;
    recording_date: string;
    river_name: string;
    design_capacity: number;
    low: number;
    medium: number;
    high: number;
    ex_high: number;
    units: string;
    antiFlow: number;
}

interface IProps {
    gaugeInfo: IGaugeInfo;
    sliderValue: number | null
}

export interface SliderComponentRef {
    getValue: () => number;
}

const GaugeInfoTable = React.forwardRef<SliderComponentRef, IProps>((props, ref) => {
    const { gaugeInfo } = props;
    const [sliderValue, setSliderValue] = React.useState<number>(props.sliderValue || 0);
    const [minValue, setMinValue] = React.useState<number>(0);
    const [maxValue, setMaxValue] = React.useState<number>(0);

    React.useImperativeHandle(ref, () => ({
        getValue: () => sliderValue,
    }));

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setSliderValue(newValue as number);
    };

    React.useEffect(() => {
        const { low, medium, high, ex_high, design_capacity, inflow, outflow } = gaugeInfo;

        const flow = Math.max(inflow / 1e5, outflow / 1e5);
        const new_design_capacity = design_capacity !== -1 ? design_capacity : flow * 10;
        // console.log(new_design_capacity)
        let values = [low, medium, high, ex_high, new_design_capacity, inflow / 1e5, outflow / 1e5].filter(value => value >= 0);
        // console.log(values)
        const min = Math.min(...values);
        const max = Math.max(...values);
        // console.log(min,max)
        setMinValue(min);
        setMaxValue(max);
        if (sliderValue === 0) {  // Only set initial slider value once to prevent overriding user input
            setSliderValue(flow);
        }

    }, [gaugeInfo]); // Removed sliderValue from dependencies to prevent re-triggering from slider updates

    return (
        <div style={{ padding: "10px" }}>
            <table className={"flow-table"}>
                <thead>
                <tr>
                    <th colSpan={7}>{gaugeInfo.gauge_name} ({gaugeInfo.units})</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <th>Inflow</th>
                    <th>Outflow</th>
                    <th>Design Capacity</th>
                    <th>Low Flood</th>
                    <th>Medium Flood</th>
                    <th>High Flood</th>
                    <th>Ex-High Flood</th>
                </tr>
                <tr>
                    <td>{gaugeInfo.inflow / 100000}</td>
                    <td>{gaugeInfo.outflow !== -1 ? (gaugeInfo.outflow / 100000) : "-"}</td>
                    <td>{gaugeInfo.design_capacity}</td>
                    <td>{gaugeInfo.low}</td>
                    <td>{gaugeInfo.medium}</td>
                    <td>{gaugeInfo.high}</td>
                    <td>{gaugeInfo.ex_high}</td>
                </tr>
                <tr>
                    <td>Anticipated Flow</td>
                    <td colSpan={5}>
                        <div>
                            <Typography id="slider-label">Value: {sliderValue}</Typography>
                            <Slider
                                sx={{ width: "100%" }}
                                value={sliderValue}
                                onChange={handleSliderChange}
                                aria-labelledby="slider-label"
                                valueLabelDisplay="auto"
                                step={(maxValue - minValue)/100}
                                min={minValue}
                                max={maxValue}
                            />
                        </div>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    );
});

export default GaugeInfoTable;
