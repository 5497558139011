import {configureStore} from "@reduxjs/toolkit";
import {baseReducer} from "./slice/base";
import {authReducer} from "./slice/auth";
import {floodReducer} from "./slice/flood";

export const store = configureStore({
    reducer: {
        base: baseReducer,
        auth: authReducer,
        flood: floodReducer
    }
});

// Define RootState type
export type RootState = ReturnType<typeof store.getState>;