import React, { useRef, useState, useEffect } from "react";
import GaugeInfoTable, { IGaugeInfo, SliderComponentRef } from "../../GaugeInfoTable";
import { AppAPIs } from "../../../../Api";
import { Button } from "@mui/material";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import { appSnackbarRef } from "../../../../App";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import OverlayVectorLayer from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";
import DrmViewModel from "../../../view_models/DrmViewModel";
import { FloodInfoTable } from "../FloodInfoTable";
import { useDispatch, useSelector } from "react-redux";
import { floodActions } from "../../../../store/slice/flood";

interface IProps {
    reachName: string;
    mapVM: MapVM;
    zoomToAOI: Function;
    setDialogActions: any;
}

export const fluvialFloodLayerUUID = MapVM.generateUUID();
const FluvialFloodInputs = (props: IProps) => {
    const [gaugeInfos, setGaugeInfos] = useState<IGaugeInfo[]>([]);
    const [loading, setLoading] = useState(false); // New state for tracking loading status
    const api = new MapApi(appSnackbarRef);
    const gauge1Ref: React.MutableRefObject<SliderComponentRef> = useRef<SliderComponentRef>();
    const gauge2Ref: React.MutableRefObject<SliderComponentRef> = useRef<SliderComponentRef>();
    const dispatch = useDispatch();
    const gaugesInput = useSelector((state: any) => state.flood.gaugesInput);

    const handleFloodInundationRequest = () => {
        if (loading) return; // Prevent duplicate requests
        setLoading(true);

        const gauge1Value = gauge1Ref.current?.getValue() || -1;
        const gauge2Value = gauge2Ref.current?.getValue() || -1;
        dispatch(floodActions.setGaugesInput([gauge1Value, gauge2Value]));

        if (gauge1Value < gaugeInfos[0]?.medium) {
            props.mapVM.getSnackbarRef()?.current?.show(
                "To calculate flood flow should be greater than " + gaugeInfos[0]?.medium + " on " + gaugeInfos[0]?.gauge_name + " gauge"
            );
            setLoading(false);
        } else if (gauge2Value < gaugeInfos[1]?.medium) {
            props.mapVM.getSnackbarRef()?.current?.show(
                "To calculate flood flow should be greater than " + gaugeInfos[1]?.medium + " on " + gaugeInfos[1]?.gauge_name + " gauge"
            );
            setLoading(false);
        } else {
            props.mapVM.getMapLoadingRef()?.current?.openIsLoading();
            api.get(AppAPIs.FF_FLUVIAL_FLOOD_INUNDATION, {
                reach_name: props.reachName,
                up_val: gauge1Value,
                down_val: gauge2Value
            }).then((payload) => {
                if (payload) {
                    let floodLayer: OverlayVectorLayer;
                    if (!(fluvialFloodLayerUUID in props.mapVM.overlayLayers)) {
                        floodLayer = DrmViewModel.createFloodLayer(props.mapVM, fluvialFloodLayerUUID);
                    } else {
                        floodLayer = props.mapVM.overlayLayers[fluvialFloodLayerUUID] as OverlayVectorLayer;
                        const source = floodLayer.olLayer.getSource();
                        console.log("source", source)
                        source.clear();
                        source.refresh();
                    }

                    floodLayer.addGeojsonFeature(payload.geojson, true);
                    floodLayer.zoomToFeatures();
                    dispatch(floodActions.setFloodMapID(payload.map_id));

                    const rightDrawer = props.mapVM.getRightDrawerRef();
                    const features = floodLayer.getFeatures();
                    const affectedPopulation = features.reduce((accumulator: number, feature) => {
                        const properties = feature.getProperties();
                        return (accumulator + parseFloat(properties["population"]));
                    }, 0);
                    const contents = (
                        <React.Fragment>
                            <h3>{props.reachName}</h3>
                            <br />
                            <FloodInfoTable
                                mapVM={props.mapVM}
                                affectedPopulation={affectedPopulation}
                               />
                        </React.Fragment>
                    );

                    rightDrawer?.current?.addContents("Flood Impact", contents);
                    rightDrawer?.current?.openDrawer();
                }
            }).catch((error) => {
                console.error("result failed with error:", error);
                props.mapVM.getSnackbarRef()?.current?.show("Failed to retrieve flood data.");
            }).finally(() => {
                props.mapVM.getMapLoadingRef()?.current?.closeIsLoading();
                dispatch(floodActions.closeDialog());
                setLoading(false); // Reset loading status
            });
        }
    };

    useEffect(() => {
        if (props.reachName !== "") {
            api.get(AppAPIs.FF_REACH_GAUGE_INFO, { reach_name: props.reachName }).then((payload) => {
                if (payload) {
                    const updatedInfo = payload.map((gi) =>
                        Object.assign(gi, { antiFlow: gi.inflow / 100000 })
                    );
                    setGaugeInfos(updatedInfo);
                    props.setDialogActions(
                        <Button onClick={handleFloodInundationRequest} disabled={loading}>
                            Calculate Flood
                        </Button>
                    );
                }
            });
        } else {
            props.mapVM.getSnackbarRef()?.current?.show("Please select reach name");
        }
    }, [props.reachName, loading]); // Include loading in dependency array

    return (
        <React.Fragment>
            <h3>{props.reachName + " Reach"}</h3>
            {gaugeInfos.length > 0 ? gaugeInfos.map((gi, index) => (
                <GaugeInfoTable
                    key={"gauge-info-" + index}
                    ref={index === 0 ? gauge1Ref : gauge2Ref}
                    gaugeInfo={gi}
                    sliderValue={gaugesInput && gaugesInput[index]}
                />
            )) : <h4 style={{ color: "red" }}>No Gauge Available </h4>}
        </React.Fragment>
    );
}

export default FluvialFloodInputs;
