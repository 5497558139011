import {Button} from "@mui/material";
import * as React from "react";
import * as XLSX from 'xlsx';
import {useDispatch, useSelector} from "react-redux";
import {floodActions} from "../../../store/slice/flood";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import {AppAPIs} from "../../../Api";
import BoundaryStatsTable from "./BoundaryStatsTable";
import {store} from "../../../store/store";
import PdfPreviewDialog from "./Report/PDFPreview";
import DrmViewModel from "../../view_models/DrmViewModel";
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { unByKey } from 'ol/Observable';
import SMSDialog, {SMSDialogRef} from "./inputs/SMSDialog";
import { Coordinate } from 'ol/coordinate';

export const FloodInfoTable = (props: { mapVM: MapVM, affectedPopulation: number}) => {
    const smsDialogRef = React.useRef<SMSDialogRef>(null);
    const dispatch = useDispatch();
    const floodMapId = useSelector((state: any) => state.flood.floodMapId)
    const [openPDFPreview, setOpenPDFPreview] = React.useState(false);
    const [locLayerTitle, setLocLayerTitle] = React.useState<string>()
    const handleChangeParameters = () => {
        // handleChangeParameters  && handleChangeParameters()
        dispatch(floodActions.openDialog())
    }
    React.useEffect(()=>{
        const layerTitle = DrmViewModel.createMarkLocationLayer(props.mapVM)
        setLocLayerTitle(layerTitle)

    }, [])

    const handleDataDownload = (data: any) => {
        // Convert JSON to worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);

        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

        // Generate Excel file and trigger download
        XLSX.writeFile(workbook, "data.xlsx");
    }
    const handleTehsilDetails = () => {
        if (floodMapId) {
            props.mapVM?.getMapLoadingRef()?.current?.openIsLoading();
            props.mapVM.getApi().get(AppAPIs.FF_AFFECTED_TEHSIL_DETAILS, {map_Id: floodMapId.map_id[0]}).then((payload) => {
                if (payload) {
                    props.mapVM.getDialogBoxRef()?.current?.openDialog({
                        content: <BoundaryStatsTable key={"district-info"} data={payload}/>,
                        actions: <Button onClick={() => handleDataDownload(payload)}>Download</Button>
                    })
                }
            }).finally(() => {
                store.dispatch(floodActions.closeDialog())
                props.mapVM.getMapLoadingRef()?.current?.closeIsLoading();
            })
        }
    }
    const handleDistrictDetails = () => {
        if (floodMapId) {
            props.mapVM?.getMapLoadingRef()?.current?.openIsLoading();
            console.log("flood map id", floodMapId)
            props.mapVM.getApi().get(AppAPIs.FF_AFFECTED_DISTRICT_DETAILS, {map_Id: floodMapId.map_id[0]}).then((payload) => {
                // console.log("district", payload)
                if (payload) {
                    props.mapVM.getDialogBoxRef()?.current?.openDialog({
                        content: <BoundaryStatsTable key={"district-info"} data={payload}/>,
                        actions: <Button onClick={() => handleDataDownload(payload)}>Download</Button>
                    })
                }
            }).finally(() => {
                props.mapVM.getMapLoadingRef()?.current?.closeIsLoading();
            })
        }
    }
    const handleMarkLocationAndSMS = () =>{
        // alert("working...")
        const map = props.mapVM.getMap();
        const clickListener = map.on('click', function (event) {
            // Get the coordinates in the map's view projection
            const coordinates :Coordinate = event.coordinate;


            // Create a marker feature
            const marker = new Feature({
                geometry: new Point(coordinates)
            });
            const layer = DrmViewModel.getVectorLayerByTitle(locLayerTitle, props.mapVM)
            const vectorSource = layer.getSource();
            // Add the marker to the vector source
            vectorSource.clear();  // Remove previous markers
            vectorSource.addFeature(marker);
            // Unregister the click event
            unByKey(clickListener);
            if (smsDialogRef.current) {
                smsDialogRef.current.openDialog(coordinates);
            }
        });
    }
    const handleGenerateReport = () => {
        setOpenPDFPreview(true)
    }
    const handleClosePDFPreview = () => {
        setOpenPDFPreview(false);
    };
    const handleStatsDetails= () =>{
        DrmViewModel.handleStatsDetails(props.mapVM)
    }
    return (
        <React.Fragment>
            <table key={"bi-table"} style={{"padding": "5px"}}>
                <thead>
                <tr key={"bi-head"}>
                    <th key={"bi-head-info"} align={"center"} colSpan={2}
                        style={{textAlign: "center"}}>Statistics
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr key={"bi-row-1"}>
                    <td key={"bi-cell-11"}>Affected Population</td>
                    <td key={"bi-cell-12"}>{props.affectedPopulation}</td>
                </tr>
                <tr key={"bi-button-row-district-1"}>
                    <td key={"bi-button-cell-district-1"} colSpan={2}>
                        <Button key={"detail-button-district-1"} onClick={() => handleDistrictDetails()}>
                            View District Stats</Button></td>
                </tr>
                <tr key={"bi-button-row-district-2"}>
                    <td key={"bi-button-cell-district-2"} colSpan={2}>
                        <Button key={"detail-button-district-2"} onClick={() => handleTehsilDetails()}>
                            View Tehsil Stats</Button></td>
                </tr>
                {/*<tr key={"bi-button-row-1"}>*/}
                {/*    <td key={"bi-button-cell"} colSpan={2}>*/}
                {/*        <Button key={"detail-button"} onClick={() => props.handleStatsDetails()}>*/}
                {/*            View Tehsil Stats </Button></td>*/}
                {/*</tr>*/}
                <tr key={"bi-button-row-details"}>
                    <td key={"bi-button-cell-details"} colSpan={2}>
                        <Button key={"detail-button-details"} onClick={() => handleStatsDetails()}>
                            View Details</Button></td>
                </tr>
                {/*{floodType && floodType == "fluvial" &&*/}
                <tr key={"bi-button-row-3"}>
                    <td key={"bi-button-cell-params"} colSpan={2}>
                        <Button key={"parameters-button-params"} onClick={() => handleChangeParameters()}>
                            Change Parameters</Button></td>
                </tr>
                {/*}*/}
                <tr key={"bi-button-row-4"}>
                    <td key={"bi-button-cell-params-1"} colSpan={2}>
                        <Button key={"generate-report-button"} onClick={() => handleGenerateReport()}>
                            Generate Report</Button></td>
                </tr>
                <tr key={"bi-button-row-5"}>
                    <td key={"bi-button-cell-params-1"} colSpan={2}>
                        <Button key={"sms-location"} sx={{"color": "red"}} onClick={() => handleMarkLocationAndSMS()}>
                            Mark Location and SMS</Button></td>
                </tr>

                </tbody>
            </table>
            <SMSDialog ref={smsDialogRef}  />
            <PdfPreviewDialog
                open={openPDFPreview}
                onClose={handleClosePDFPreview}
                mapVM={props.mapVM}
            />
        </React.Fragment>
    )
}
