import React, {useState} from 'react';
import {useDispatch} from 'react-redux';
import {Button, TextField, Box, Typography, Container, SvgIcon} from '@mui/material';
import {ReactComponent as GoogleIcon} from './google-icon.svg';
import {authActions} from "../../../store/slice/auth";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef} from "../../../App"; // Ensure this path is correct

interface IProps {
    closeDropDown: Function
}

const LoginForm = (props: IProps) => {
    const dispatch = useDispatch();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const handleSignIn = async (event: any) => {
        event.preventDefault(); // Prevent form submission default behavior

        // Define the URL
        const url = MapApi.getURL("/api/jwt/auth/login")
        // Define the data payload
        const formData = new URLSearchParams();
        formData.append('grant_type', '');
        formData.append('username', username);
        formData.append('password', password);
        formData.append('scope', '');
        formData.append('client_id', '');
        formData.append('client_secret', '');

        // Define the headers
        const headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
        };

        try {
            // Make the POST request
            const response = await fetch(url, {
                method: 'POST',
                headers: headers,
                body: formData,
            });
            props.closeDropDown();
            const data = await response.json();

            if (response.ok) {
                appSnackbarRef?.current?.show("Welcome " + data.userInfo.name)
                // Dispatch actions to update the Redux store
                dispatch(authActions.setUserInfo(data.userInfo)); // Or adjust according to your API response
                dispatch(authActions.isUserAuthenticated(true));
                dispatch(authActions.setTokens({
                    accessToken: data.userInfo.accessToken,
                    refreshToken: data.userInfo.refreshToken
                }))

            } else {
                appSnackbarRef?.current?.show('Login failed:', data.message)
                // Handle login failure, possibly update state with error message
            }
        } catch (error) {
            appSnackbarRef?.current?.show('Login failed due to some error')
            console.error('Error:', error);
            // Handle error, possibly update state with error message
        }
    };

    const handleGoogleLogin = () => {
        console.log('Login with Google initiated');
        // Here you would integrate the actual Google login functionality
    };

    return (
        <Container component="main" sx={{width: "350px", marginLeft: "30px"}}>
            <Box
                sx={{
                    margin: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign In
                </Typography>
                <Box component="form" noValidate onSubmit={handleSignIn} sx={{mt: 1}}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{mt: 3, mb: 2}}
                    >
                        Sign In
                    </Button>
                    <Button
                        startIcon={<SvgIcon component={GoogleIcon} viewBox="0 0 260 260"/>}
                        fullWidth
                        variant="outlined"
                        sx={{mt: 2}}
                        onClick={handleGoogleLogin}
                    >
                        Login with Google
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default LoginForm;
