import React, {useState} from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import MapApi, {MapAPIs} from "damap/dist/esm/ol-map/utils/MapApi";
import {IXYZLayerInfo} from "damap/dist/esm/ol-map/layers/overlay_layers/XYZLayer";
import {loadingRef} from "../../../App";

interface IProps {
    mapVM: MapVM
}

const geeLayersInfo: Object = {
    "temperature": {name: 'Temperature', uuid: MapVM.generateUUID(), layerTitle: "Temperature"},
    "snow_cover": {name: 'Snow Cover', uuid: MapVM.generateUUID(), layerTitle: "Snow Cover"},
    "precipitation":{name: 'Precipitation', uuid: MapVM.generateUUID(), layerTitle: "Chirps Precipitation"},
    "water_level": {name: 'Water level', uuid: MapVM.generateUUID(), layerTitle: "Water Rise"},
    "surface_runoff": {name: 'Surface Runoff', uuid: MapVM.generateUUID(), layerTitle: "Surface Runoof"}

}

export const addGEELayer = (selectedLayerKey: string, mapVM) => {
    const isExist = mapVM.isGEELayerExist(geeLayersInfo[selectedLayerKey]['uuid'])
    if (!isExist) {
        loadingRef?.current?.openLoader()

        mapVM.getApi().get(MapAPIs.DCH_GEE_LAYER, {layer_type: selectedLayerKey}).then((payload) => {
            // console.log("payload", payload)
            const layerInfo: IXYZLayerInfo = {
                uuid: geeLayersInfo[selectedLayerKey]['uuid'],
                title: geeLayersInfo[selectedLayerKey]['layerTitle'],
                url: payload,
                legendURL: MapApi.getURL(MapAPIs.DCH_GEE_LAYER_LEGEND, {layer_type: selectedLayerKey})
            }
            mapVM.addGEELayer(layerInfo)

        }).catch((e)=>{
            console.log(e)
        }).finally(()=>{
            loadingRef?.current?.closeLoader()
        })
    // } else {
    //     mapVM.getSnackbarRef()?.current?.show("Layer already exists");
    }
}

const GoogleEarthEngineButton = (props: IProps) => {
    // const handleClick = () = {}
    const [anchorEl, setAnchorEl] = useState(null);
    const [layer, setLayer] = useState('');


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (selectedLayerKey) => {
        if (selectedLayerKey && selectedLayerKey != '') {
            setLayer(selectedLayerKey);
            addGEELayer(selectedLayerKey, props.mapVM)
        }
        setAnchorEl(null);
    };
    return (
        <React.Fragment>
            <Button
                onClick={handleClick}
                variant="contained"
            >
                Google Earth Layer: {layer}
            </Button>
            <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => handleClose(null)}
            >
                <MenuItem onClick={() => handleClose('')}> </MenuItem>
                {Object.keys(geeLayersInfo).map((key: string) => <MenuItem key={key}
                    onClick={() => handleClose(key)}>{geeLayersInfo[key]['name']}</MenuItem>)}

            </Menu>
        </React.Fragment>

    );
}

export default GoogleEarthEngineButton