import * as React from "react";
import "./main.css"

const MainPage = () => {
    return (
        <div className="main">
            <div className="overlap-group-wrapper">
                <div className="overlap-group">
                    <div className="shaded-background"/>
                    <img className="COMING-SOON" alt="Coming SOON" src="/static/img/COMING-SOON.svg"/>
                    <img className="wait" alt="Wait" src="/static/img/wait-1.gif"/>
                    <img className="pmd-icon" alt="PMD icon" src="/static/img/PMD-icon.png"/>
                    <img className="whh-icon" alt="WHH icon" src="/static/img/WHH-icon.png"/>
                    <div className="icons-group">
                        <div className="flood">
                            <a href={"/ff"} title={"flood analysis"}>
                                <img className="element" alt="Flood" src="/static/img/flood.png"/>
                            </a>
                            <div className="label">
                                <div className="flood-analysis">
                                    Flood
                                    <br/>
                                    Analysis
                                </div>
                            </div>

                        </div>
                        <div className="heat">
                            <a href={"/heat_analysis"} title={"heat analysis"}>
                                <img className="element" alt="Element" src="/static/img/heat.png"/>
                                <div className="label">
                                    <div className="heat-analysis">
                                        Heat
                                        <br/>
                                        Analysis
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div className="drought">
                            <a href={"/heat_analysis"} title={"heat analysis"}>
                                <img className="element" alt="Drought" src="/static/img/drought.png"/>
                                <div className="label">
                                    <div className="drought-analysis">
                                        Drought
                                        <br/>
                                        Analysis
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MainPage;
