// dialogSlice.js
import {createSlice} from '@reduxjs/toolkit';

const floodSlice = createSlice({
    name: 'flood',
    initialState: {
        isDialogOpen: false,
        floodType: null,
        aoi_key: null,
        dialogActions: null,
        gaugesInput: null,
        displayName: '',
        floodMapId: null,
        selectedPRValue: 0
    },
    reducers: {
        resetData: (state) => {
            state.isDialogOpen = false
            state.floodType = null
            state.aoi_key = null
            state.dialogActions = null
            state.gaugesInput = null
            state.floodMapId = null
        },
        openDialog: (state) => {
            state.isDialogOpen = true;

        },
        closeDialog: (state) => {
            state.isDialogOpen = false;
        },
        setParams: (state, action) => {
            state.floodType = action.payload.floodType
            state.aoi_key = action.payload.aoi_key
        },
        setDisplayName: (state, action) => {
            state.displayName = action.payload
        },
        setDialogActions: (state, action) => {
            state.dialogActions = action.payload.dialogActions
        },
        setGaugesInput: (state, action) => {
            state.gaugesInput = action.payload
        },
        setFloodMapID: (state, action) => {
            state.floodMapId = action.payload
        },
        setSelectedPRValue: (state, action) => {
            state.selectedPRValue = action.payload
        },

    },
});

export const floodActions = floodSlice.actions;

export const floodReducer = floodSlice.reducer;
