import * as React from "react";
import {Route, Routes, useNavigate} from "react-router-dom";
import FloodForecast from "./modules/containers/FloodForecast";
import Drought from "./modules/containers/Drought";
import MainPage from "./main";
import LayerInfo from "damap/dist/esm/admin/containers/LayerInfo";
import MapInfo from "damap/dist/esm/admin/containers/MapInfo";
import HeatAnalysis from "./modules/containers/HeatAnalysis";
import DAMap from "damap/dist/esm/ol-map/containers/DAMap";
import {useSelector} from "react-redux";
import {appSnackbarRef} from "./App";
import LayerDesigner from "damap/dist/esm/ol-map/containers/LayerDesigner";
import RiskAssessmentRequest from "./modules/components/utilities/RiskAssessmentRequest";
import RiskAssessmentResponse from "./modules/containers/RiskAssessmentResponse";
import ManageRiskAssessment from "./modules/containers/manage/ManageRiskAssessment";

interface IProps {
    children: JSX.Element;
}

const Protected = (props: IProps) => {
    // const isAuthenticated = UserUtils.isLoggedIn()
    // const [auth, setAuth] = React.useState<boolean>(false);
    const auth = useSelector((state: any) => state.auth.isAuthenticated)
    const navigate = useNavigate();
    const {children} = props;

    if (!auth) {
        navigate("/");
        appSnackbarRef?.current?.show(
            "Failed to login. " + "Please check your credentials"
        );
    }

    return <React.Fragment>{auth ? children : <><h3>Please login to access this page</h3></>}</React.Fragment>;
};

const ContentRouter = () => {
    return (
        <React.Fragment>
            <Routes>
                <Route path={"/manage/layer_info/"} element={<Protected><LayerInfo/></Protected>}/>
                <Route path={"/manage/map_info/"} element={<Protected><MapInfo/></Protected>}/>
                <Route path={"/manage/risk_assessment/"} element={<Protected><ManageRiskAssessment/></Protected>}/>

                <Route path={"/ViewMap/:mapId/"} element={<Protected><DAMap/></Protected>}/>
                <Route path={"/EditMap/:mapId/"} element={<Protected><DAMap isEditor={true}/></Protected>}/>
                <Route path="/designer/:layerId/" element={<Protected><LayerDesigner /></Protected>} />
                <Route path="/ff/" element={<FloodForecast/>}/>
                <Route path="/drought" element={<Drought/>}/>
                <Route path="/heat_analysis" element={<HeatAnalysis/>}/>
                <Route path={"/rar/:request_id"} element={<RiskAssessmentResponse />} />
                <Route path="/" element={<MainPage/>}/>
            </Routes>
        </React.Fragment>
    )
}

export default ContentRouter
