import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@mui/material';
import * as olProj from 'ol/proj';
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import { Feature } from 'ol';
import { Point } from 'ol/geom';
import { Vector as VectorLayer } from 'ol/layer';
import { Vector as VectorSource } from 'ol/source';
import { Style, Icon } from 'ol/style';
import RiskAssessmentRequest from "./RiskAssessmentRequest";

const parseCoordinate = (input: string): number | null => {
    try {
        // Remove leading and trailing whitespaces
        input = input.trim();

        const decimalRegex = /^-?\d+(\.\d+)?$/;
        const dmsRegex = /^(\d+)[°\s]+(\d+)'?(\d+(?:\.\d+)?)?"?\s*([NSEW])?$/i;


        if (decimalRegex.test(input)) {
            return parseFloat(input);
        }

        const dmsMatch = input.match(dmsRegex);
        if (dmsMatch) {
            let degrees = parseFloat(dmsMatch[1]);
            let minutes = parseFloat(dmsMatch[2]);
            let seconds = parseFloat(dmsMatch[3]);
            let direction = dmsMatch[4];

            let decimal = degrees + minutes / 60 + seconds / 3600;
            if (direction === 'S' || direction === 'W') {
                decimal = -decimal;
            }

            console.log(decimal);
            return decimal;
        }
    } catch (e) {
        console.error(e);
    }

    return null;
};

interface  IProps{
    mapVM : MapVM
}

const ZoomToPOIDialog = forwardRef((props:IProps, ref) => {
    const [open, setOpen] = useState(false);
    const [latitude, setLatitude] = useState('');
    const [longitude, setLongitude] = useState('');

    useImperativeHandle(ref, () => ({
        openDialog() {
            setOpen(true);
        }
    }));

    const handleClose = () => {
        setOpen(false);
    };

    const handleZoom = () => {
        const lat = parseCoordinate(latitude);
        const lon = parseCoordinate(longitude);

        if (lat === null || lon === null) {
            handleClose();
            alert('Please enter valid coordinates.');
            return;
        }

        // Convert from EPSG:4326 to EPSG:3857
        const [x, y] = olProj.transform([lon, lat], 'EPSG:4326', 'EPSG:3857');
        const tol = 3000; // Tolerance in meters
        const extent = [x - tol, y - tol, x + tol, y + tol];

        // Add a marker at the zoomed location
        const marker = new Feature({
            geometry: new Point([x, y]),
        });

        const markerStyle = new Style({
            image: new Icon({
                src: 'https://openlayers.org/en/latest/examples/data/icon.png',
                anchor: [0.5, 1], // Adjust the anchor so the marker points to the location
            }),
        });

        marker.setStyle(markerStyle);

        // Create a vector source and layer to hold the marker
        const vectorSource = new VectorSource({
            features: [marker],
        });

        const markerLayer = new VectorLayer({
            source: vectorSource,
        });

        // Add the marker layer to the map
        props?.mapVM?.getMap().addLayer(markerLayer);

        // Zoom to the extent
        props?.mapVM?.getMap().getView().fit(extent, { duration: 1000 });

        handleClose();
        const rightDrawer = props?.mapVM?.getRightDrawerRef().current;
        rightDrawer?.addContents("Area of Interest", <RiskAssessmentRequest mapVM={props.mapVM }/>)
        rightDrawer?.openDrawer()
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Zoom To POI</DialogTitle>
            <DialogContent>
                <TextField
                    label="Latitude"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    fullWidth
                    helperText={"Enter in Decimal or DMS format (e.g., 31.5 or 31°30'00\" N)"}
                />
                <TextField
                    label="Longitude"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    fullWidth
                    style={{ marginTop: '10px' }}
                    helperText={"Enter in Decimal or DMS format (e.g., 74.5 or 74°30'00\" E)"}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleZoom} disabled={!latitude || !longitude}>
                    Zoom
                </Button>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
});

export default ZoomToPOIDialog;
