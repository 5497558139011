import React, {RefObject, useEffect, useRef, useState} from "react";
import {useParams} from "react-router-dom";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef} from "../../App";
import MapContainer from "damap/dist/esm/ol-map/containers/MapContainer";
import DrmViewModel from "../view_models/DrmViewModel";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import Map from "ol/Map";
import OverlayVectorLayer from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";
import {AppAPIs} from "../../Api";
import XYZLayer, {IXYZLayerInfo} from "damap/dist/esm/ol-map/layers/overlay_layers/XYZLayer";
import {Slider, Box, Typography} from "@mui/material";

const RiskAssessmentResponse = () => {
    const {request_id} = useParams(); // Get the request_id from the URL
    const riskMapViewRef = useRef(null); // Ref for the MapContainer
    const mapUUID: string = "77d677084f3e11eebc0eacde48001122";
    const api: MapApi = new MapApi(appSnackbarRef); // Assuming appSnackbarRef is defined elsewhere in your app
    const [mapVM, setMapVM] = useState<MapVM>();
    const [olMap, setOlMap] = useState<Map>();
    const [floodLayer, setFloodLayer] = useState<OverlayVectorLayer>();
    const [surfaceList, setSurfaceList] = useState<{ name: string, uuid: string }[]>([]);
    const [dateRange, setDateRange] = useState<Date[]>([]);
    const [selectedDate, setSelectedDate] = useState<number | null>(null);
    const [xyzLayers, setXyzLayers] = useState<{ [key: string]: XYZLayer }>({}); // Store layers by their UUID

    useEffect(() => {
        if (!mapVM) {
            const mvm = riskMapViewRef?.current?.getMapVM();
            if (mvm) {
                setMapVM(mvm);
            }
        }
    }, [riskMapViewRef?.current?.getMapVM()]);

    useEffect(() => {
        if (!olMap && mapVM) {
            const mapInterval = setInterval(() => {
                const map = mapVM.getMap();
                if (map) {
                    setOlMap(map);
                    clearInterval(mapInterval); // Stop the interval once the map is set
                }
            }, 100); // Check every 100ms, you can adjust the interval as needed

            return () => clearInterval(mapInterval); // Clean up the interval on component unmount
        }
    }, [olMap, mapVM]);

    useEffect(() => {
        if (olMap) {
            const uuid = MapVM.generateUUID();
            const layer = DrmViewModel.createFloodLayer(mapVM, uuid);
            setFloodLayer(layer);
        }
    }, [olMap]);

    useEffect(() => {
        if (floodLayer && request_id) {
            (async () => {
                try {
                    mapVM.getMapLoadingRef().current?.openIsLoading();
                    api.get(AppAPIs.DRM_FLOOD_HAZARD_RESPONSE, {request_id: request_id})
                        .then(payload => {
                            if (payload) {
                                const geojson = payload.geojson;
                                floodLayer?.addGeojsonFeature(geojson, true);
                                floodLayer?.zoomToFeatures();
                                setSurfaceList(payload.surface_list.map(surface => ({
                                    name: surface,
                                    uuid: MapVM.generateUUID()
                                })));
                                const dateRange = payload.date_range;
                                setDateRange([new Date(dateRange["min"]), new Date(dateRange["max"])]);
                                setSelectedDate(new Date(dateRange["min"]).getTime());
                            }
                        }).catch(error => {
                        console.error("Error fetching GeoJSON data:", error);
                        appSnackbarRef.current?.show("Failed to fetch data");
                    }).finally(() => {
                        mapVM.getMapLoadingRef().current?.closeIsLoading();
                    });
                } catch (error) {
                    appSnackbarRef.current?.show("Request Id or map is not available");
                    console.error("Error in fetchGeoJSON:", error);
                }
            })();
        }
    }, [floodLayer, request_id]); // Fetch GeoJSON whenever request_id changes

    useEffect(() => {
        if (surfaceList.length > 0 && dateRange.length === 2) {
            const newLayers = {...xyzLayers};
            surfaceList.forEach((surface) => {
                if (!newLayers[surface.uuid]) {
                    const date = new Date(selectedDate || dateRange[0]).toISOString().split('T')[0];
                    const url = MapApi.getURL(AppAPIs.DRM_HAZARD_SURFACE, {
                        request_id: request_id,
                        date: date,
                        surface_name: surface.name
                    });
                    const legend_url = MapApi.getURL(AppAPIs.DRM_HAZARD_LAYER_LEGEND, {
                        request_id: request_id,
                        surface_name: surface.name
                    })
                    const info: IXYZLayerInfo = {
                        title: surface.name,
                        url: url,
                        uuid: surface.uuid,
                        visible: true,
                        legendURL: legend_url,
                    };

                    const newXyzLayer = new XYZLayer(info, mapVM);
                    mapVM.getMap().addLayer(newXyzLayer.olLayer);
                    newLayers[surface.uuid] = newXyzLayer; // Store the layer in state
                }
            });
            setXyzLayers(newLayers); // Update the state with new layers
        }
    }, [surfaceList, dateRange, selectedDate]);

    const handleDateChange = (event, newValue) => {
        setSelectedDate(newValue);

        const date = new Date(newValue).toISOString().split('T')[0];
        console.log(`Selected Date: ${date}`);

        // Update each XYZLayer with the new date
        Object.values(xyzLayers).forEach((layer) => {
            const surface = surfaceList.find(s => s.uuid === layer.uuid);
            if (surface) {
                // mapVM.getMapLoadingRef().current.openIsLoading()
                const url = MapApi.getURL(AppAPIs.DRM_HAZARD_SURFACE, {
                    request_id: request_id,
                    date: date,
                    surface_name: surface.name
                });

                layer.updateSourceURL(url);
            }
        });

        olMap.render(); // Re-render the map to reflect changes
    };

    return (
        <React.Fragment>
            <Box position="relative" width="100%" height="100%">
                <MapContainer ref={riskMapViewRef} uuid={mapUUID} title={"Flood Forecast"}/>
                {dateRange.length > 0 && selectedDate !== null && (
                    <Box
                        sx={{
                            position: "absolute",
                            bottom: 10,
                            left: "50%",
                            transform: "translateX(-50%)",
                            width: "50%",
                            padding: 2,
                            bgcolor: "rgba(255, 255, 255, 0.8)"
                        }}
                    >
                        <Typography gutterBottom>
                            Date: {new Date(selectedDate).toISOString().split('T')[0]}
                        </Typography>
                        <Slider
                            value={selectedDate}
                            min={dateRange[0].getTime()}
                            max={dateRange[1].getTime()}
                            onChange={handleDateChange}
                            step={24 * 60 * 60 * 1000} // Step by day in milliseconds
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => new Date(value).toISOString().split('T')[0]}
                            aria-labelledby="date-slider"
                        />
                    </Box>

                )}
            </Box>
        </React.Fragment>
    );
};

export default RiskAssessmentResponse;
