import React, {useEffect, useRef} from "react";
import MapContainer from "damap/dist/esm/ol-map/containers/MapContainer";
import MapView from "damap/dist/esm/ol-map/containers/MapView";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef} from "../../App";
import {AppAPIs} from "../../Api";
import OverlayVectorLayer, {IOverLayVectorInfo} from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";
import IconButton from '@mui/material/IconButton';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import BarChartIcon from '@mui/icons-material/BarChart';
import WKT from 'ol/format/WKT';
import "../../static/form_cmp.css"
import HeatWaveChart from "../components/HeatAnalysis/HeatWaveChart";

const HeatAnalysis = () => {
    const mapViewRef: React.RefObject<MapView> = useRef(null);
    const mapUUID: string = "63721fdee77c11eeb84416755fa76cbc";
    const api: MapApi = new MapApi(appSnackbarRef)
    const [cityList, setCityList] = React.useState([])
    // State to store the selected city ID
    const [layerUuid, setLayerUuid] = React.useState<string>('');
    const [selectedCityId, setSelectedCityId] = React.useState('');
    const [selectedFeature, setSelectedFeature] = React.useState<any>();

    useEffect(() => {
        const mapVM = mapViewRef.current?.getMapVM();
        // let isActive = true; // flag to check component mount status
        if (mapVM) {
            const uuid = mapVM.getMapUUID()
            setLayerUuid(uuid)
            api.get(AppAPIs.HA_GFC_CITIES).then((res) => {
                // if (!isActive) return;
                console.log("heat_analysis",res)
                const info: IOverLayVectorInfo = {
                    uuid: uuid,
                    title: "GFC Layers",
                    geomType: "Point",
                    style: res.style
                };
                const layer = new OverlayVectorLayer(info, mapVM);
                layer.addGeojsonFeature(res.geojson, true);
                mapVM.addOverlayLayer(layer);

                const cities = res.geojson?.features?.map((feature) => ({
                    name: feature.properties['city'],
                    id: feature.properties['id']
                }));
                // Sort the cities alphabetically by name
                cities.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

                setCityList(cities);
            }).catch(error => {
                console.error("Failed to load cities:", error);
                appSnackbarRef.current?.show("Error loading data");
            });
        }


    }, [mapViewRef.current])
    const handleSelectChange = (event) => {
        setSelectedCityId(event.target.value);
    };
    const selectCityFeature = (zoomTo = true) => {
        const mapVM = mapViewRef.current?.getMapVM();
        const daLayer: OverlayVectorLayer = mapVM.getOverlayLayer(layerUuid) as OverlayVectorLayer;
        daLayer.getSource().forEachFeature((feature) => {
            // console.log("feature", selectedCity, feature.get('id'))
            if (feature.get('id') == selectedCityId) {
                // console.log('Feature found:', feature);
                setSelectedFeature(feature)
                const wktFormatter = new WKT();
                const serializedWKT = wktFormatter.writeFeature(feature, {
                    featureProjection: 'EPSG:3857' // specify the map projection
                });
                // console.log(serializedWKT)
                mapVM.selectionLayer.addWKT2Selection(serializedWKT, true)
                if (zoomTo) {
                    mapVM.selectionLayer.zoomToSelection()
                    mapVM.selectionLayer.clearSelection()
                }

            }
        });
    }
    const handleZoomClick = () => {
        if (selectedCityId == '') {
            appSnackbarRef.current?.show("Please select city from dropdown");
        } else {
            selectCityFeature()
        }
    };

    const handleStatsClick = () => {
        if (selectedCityId == '') {
            appSnackbarRef.current?.show("Please select city from dropdown");
        } else {

            api.get(AppAPIs.HA_GFC_CITIES_HEATWAVE_FORECAST, {city_id: selectedCityId})
                .then((payload) => {
                    console.log("heat wave", payload)
                    const mapVM = mapViewRef.current?.getMapVM();

                    const dialogBoxRef = mapVM.getDialogBoxRef()
                    const data = {
                        title: "Heat Wave Chart",
                        content: <div key={"chart_div-1"} style={{"width":"700px"}}><HeatWaveChart key={"chart-1"} forecast_data={payload.forecast} thresholds={payload.thresholds}/></div>
                    }
                    dialogBoxRef.current.openDialog(data)
                });
        }

    };

    return (
        <React.Fragment>
            <MapContainer ref={mapViewRef} uuid={mapUUID} title={"Heat Analysis"}>
                <select className={"daSelect"} onChange={handleSelectChange}>
                    {cityList.map((city) => (
                        <option key={city.id} value={city.id}>{city.name}</option>
                    ))}
                </select>
                <IconButton onClick={handleZoomClick}>
                    <ZoomInIcon/>
                </IconButton>
                <IconButton onClick={handleStatsClick}>
                    <BarChartIcon/>
                </IconButton>
            </MapContainer>

        </React.Fragment>

    );
}

export default HeatAnalysis;