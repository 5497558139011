// FloodInputForm.js
import React, {forwardRef, useImperativeHandle, useState} from 'react';
import {
    Grid,
    FormControl,
    RadioGroup,
    FormControlLabel,
    Radio,
    Autocomplete,
    TextField
} from '@mui/material';
import {AppAPIs} from "../../../Api";
import MapApi from "damap/dist/esm/ol-map/utils/MapApi";
import {appSnackbarRef, loadingRef} from "../../../App";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";

interface IOption {
    id : string
    name: string
}
interface IProps {
    mapVM: MapVM
}
const AOISelector = forwardRef((props: IProps, ref)  => {
    /****
     pluvial : rainfall intensity exceeding infiltration capacity
     fluvial: high-water levels in river channels exceeding bank heights and/or causing dyke breach
     ****/
    const [floodType, setFloodType] = useState<string>('fluvial'); // Set default value
    const [aoi, setAOI] = useState<any>({});
    const [aoiOptions, setAoiOptions] = React.useState<IOption[]>([])


    // Expose selectedOption and dropdownValue to the parent component using useImperativeHandle
    useImperativeHandle(ref, () => ({
        floodType,
        aoi,
    }));
    const api: MapApi = new MapApi(appSnackbarRef)
    React.useEffect(() => {
        loadingRef.current?.openLoader()
        if(floodType == "fluvial") {
            api.get(AppAPIs.FF_REACH_NAME).then((payload) => {
                if (payload) {
                    // console.log("fluvial", payload)
                    setAoiOptions(payload.map(((p: any) => ({id:p, "name": p}) )))
                    loadingRef?.current?.closeLoader();
                }
            }).catch((e)=>{
                console.error(e)
            })
        }else if(floodType == "pluvial"){
            api.get(AppAPIs.FF_CATCHMENT_NAME).then((payload) => {
                if (payload) {
                    // console.log("pluvial", payload)
                    setAoiOptions(payload.map((p: any) => ({id: p.id, name: p.name})))
                    loadingRef?.current?.closeLoader();
                }
            }).catch((e)=>{
                console.error(e)
            })
        }else{
            api.get(AppAPIs.FF_DISTRICT_NAME).then((payload) => {
                if (payload) {
                    // console.log("pluvial", payload)
                    setAoiOptions(payload.map((p: any) => ({id: p.id, name: p.name})))
                    loadingRef?.current?.closeLoader();
                }
            }).catch((e)=>{
                console.error(e)
            })
        }
    }, [floodType])
    const handleRadioChange = (event) => {
        const newSelectedOption = event.target.value;
        setFloodType(newSelectedOption);

        // Reset the dropdown value when changing the radio option
        setAOI('');
    };

    const handleAOIChange = (event: any) => {
        setAOI(event.target.value);
    };
    const handleAutocompleteChange = (event, newValue) => {
        // Assuming handleAOIChange expects the value directly
        handleAOIChange({ target: { value: newValue ? newValue : {} } });
    };

    const getLabel = () =>{
        switch(floodType){
            case "fluvial":
                return 'Select Reach'
            case "pluvial":
                return 'Select Catchment'
            case "district":
                return 'Select District'
        }
    }

    const selectStyle = {
        width: '200px', // Set your desired width
        height: '40px', // Set your desired height
        marginTop: '10px',
    };

    return (
        <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item>
                <FormControl component="fieldset">
                    <RadioGroup
                        aria-label="fluvial-pluvial-district"
                        name="fluvial-pluvial-district"
                        value={floodType}
                        onChange={handleRadioChange}
                        row
                        style={{ justifyContent: 'center' }}
                    >
                        <FormControlLabel value="fluvial" control={<Radio />} label="Fluvial" />
                        <FormControlLabel value="pluvial" control={<Radio />} label="Pluvial" />
                        <FormControlLabel value="district" control={<Radio />} label="District" />
                    </RadioGroup>
                </FormControl>
            </Grid>
            <Grid item>
                <FormControl style={selectStyle}>
                    <Autocomplete
                        value={aoiOptions.find(option => option.id === aoi.id) || null}
                        onChange={handleAutocompleteChange}
                        options={aoiOptions}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={getLabel()}
                                variant="outlined"
                            />
                        )}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
});

export default AOISelector;
