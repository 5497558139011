import React from 'react';
import { Chart } from 'react-google-charts';

const options = {
    title: "Heatwave Analysis",
    curveType: "function",
    legend: { position: "bottom" },
    series: {
        0: { color: '#0000FF' }, // Heatwave Value - Blue
        1: { color: '#00FF00' }, // Mild - Green
        2: { color: '#FFFF00' }, // Moderate - Yellow
        3: { color: '#FFA500' }, // Severe - Orange
        4: { color: '#FF0000' }  // Extreme - Red
    }
};

const HeatWaveChart = ({ forecast_data, thresholds }) => {
    // Sort thresholds in the desired order
    const sortedThresholds = thresholds.sort((a, b) => {
        const order = ['Mild', 'Moderate', 'Severe', 'Extreme'];
        return order.indexOf(a.status) - order.indexOf(b.status);
    });

    // Prepare threshold columns
    const thresholdColumns = sortedThresholds.map(threshold => threshold.status);

    // Generate chart data from props
    const chartData = [
        ["Date", "Heatwave Value", ...thresholdColumns], // Header row with dynamic threshold columns
        ...forecast_data.map(item => [
            new Date(item.date).toLocaleString(),
            item.heatwave_value,
            ...sortedThresholds.map(threshold => threshold.parameter_value)
        ])
    ];

    return (
        <Chart
            width={'100%'}
            height={'400px'}
            chartType="LineChart"
            loader={<div>Loading Chart</div>}
            data={chartData}
            options={options}
        />
    );
};

export default HeatWaveChart;
