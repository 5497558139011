import React from "react"
import {Button} from "@mui/material";

const styles = {
    table: {
        borderCollapse: 'collapse' as const,
        margin: '25px 0',
        fontSize: '18px',
        // textAlign: 'left' as const,
        width: '100%'
    },
    thead: {
        backgroundColor: '#215b4f',
        color: '#ffffff'
    },
    th: {
        padding: '12px 15px',
        alignItems: 'center',
        alignContent:'center',
        textAlign: 'center' as const,
    },
    td: {
        padding: '12px 15px'
    },
    tbodyTr: {
        borderBottom: '1px solid #dddddd'
    },
    tbodyTrEven: {
        backgroundColor: '#f3f3f3'
    },
    tbodyTrLast: {
        borderBottom: '2px solid #009879'
    },
    tbodyTrHover: {
        backgroundColor: '#f1f1f1'
    }
};
export const toSentenceCase = (str) => {
    return str.toLowerCase().replace(/(^\w)|(\s\w)/g, (match) => match.toUpperCase());
}
const BoundaryStatsTable = (props: { data: any }) => {
    // const [open, setOpen] = React.useState(false);
    return (
        <React.Fragment>
            <table style={styles.table}>
                <thead style={styles.thead}>
                <tr>
                    {Object.keys(props.data[0]).map((key) =>
                        key.toLowerCase() !== 'id' &&
                        <th style={styles.th}>{toSentenceCase(key.replace("_", " "))}</th>
                    )}
                </tr>
                </thead>
                <tbody>
                {props.data.map((row, index) => (
                    <tr
                        key={row.id}
                        style={{
                            ...styles.tbodyTr,
                            ...(index % 2 === 0 ? styles.tbodyTrEven : {}),
                            ...(index === props.data.length - 1 ? styles.tbodyTrLast : {})
                        }}
                        onMouseEnter={(e) => e.currentTarget.style.backgroundColor = styles.tbodyTrHover.backgroundColor}
                        onMouseLeave={(e) => e.currentTarget.style.backgroundColor = index % 2 === 0 ? styles.tbodyTrEven.backgroundColor : ''}
                    >
                        {Object.keys(row).map((key, index) =>
                            key.toLowerCase() !== 'id' &&
                            <td key={row.key + "-" + index} style={styles.td}>{row[key]}</td>
                        )}
                    </tr>
                ))}
                </tbody>
            </table>
        </React.Fragment>
    )
}
export default BoundaryStatsTable