import {createSlice} from "@reduxjs/toolkit";

export const BaseSlice = createSlice({
    name: "base",
    initialState: {
        // openDrawer: true,
        // topAppbarHeight: 0,
        // dockedHeight: 20,
        // appbarColor: "#800000",
        // appbarHeight: 60,
    },
    reducers: {
        // handleDrawer: (state, action) => {
        //     state.openDrawer = action.payload
        // },
        // setTopAppbarHeight: (state, action) => {
        //     state.topAppbarHeight = action.payload
        // }
    }
});

export const baseActions = BaseSlice.actions;
export const baseReducer = BaseSlice.reducer;
