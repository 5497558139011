// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.daSelect {
    width: 200px; /* Set the width of the dropdown */
    padding: 8px; /* Padding inside the dropdown */
    border: 1px solid rgba(255, 255, 255, 0.3); /* Light white border for visibility */
    border-radius: 4px; /* Rounded corners */
    background-color: rgba(0, 0, 0, 0.6); /* Transparent black background */
    color: white; /* White font color */
    appearance: none; /* Removes default styling by the browser */
    -webkit-appearance: none; /* Removes default styling for WebKit browsers */
    -moz-appearance: none; /* Removes default styling for Mozilla browsers */
}

.daSelect:hover {
    border-color: rgba(255, 255, 255, 0.5); /* Slightly brighter border on hover */
}

.daSelect:focus {
    outline: none; /* Removes the default focus outline */
    border-color: rgba(255, 255, 255, 0.7); /* Even brighter border when focused */
}
`, "",{"version":3,"sources":["webpack://./src/static/form_cmp.css"],"names":[],"mappings":"AAAA;IACI,YAAY,EAAE,kCAAkC;IAChD,YAAY,EAAE,gCAAgC;IAC9C,0CAA0C,EAAE,sCAAsC;IAClF,kBAAkB,EAAE,oBAAoB;IACxC,oCAAoC,EAAE,iCAAiC;IACvE,YAAY,EAAE,qBAAqB;IACnC,gBAAgB,EAAE,2CAA2C;IAC7D,wBAAwB,EAAE,gDAAgD;IAC1E,qBAAqB,EAAE,iDAAiD;AAC5E;;AAEA;IACI,sCAAsC,EAAE,sCAAsC;AAClF;;AAEA;IACI,aAAa,EAAE,sCAAsC;IACrD,sCAAsC,EAAE,sCAAsC;AAClF","sourcesContent":[".daSelect {\n    width: 200px; /* Set the width of the dropdown */\n    padding: 8px; /* Padding inside the dropdown */\n    border: 1px solid rgba(255, 255, 255, 0.3); /* Light white border for visibility */\n    border-radius: 4px; /* Rounded corners */\n    background-color: rgba(0, 0, 0, 0.6); /* Transparent black background */\n    color: white; /* White font color */\n    appearance: none; /* Removes default styling by the browser */\n    -webkit-appearance: none; /* Removes default styling for WebKit browsers */\n    -moz-appearance: none; /* Removes default styling for Mozilla browsers */\n}\n\n.daSelect:hover {\n    border-color: rgba(255, 255, 255, 0.5); /* Slightly brighter border on hover */\n}\n\n.daSelect:focus {\n    outline: none; /* Removes the default focus outline */\n    border-color: rgba(255, 255, 255, 0.7); /* Even brighter border when focused */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
