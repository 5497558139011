import React from 'react';

interface CustomAlertProps {
    message: string;
    onClose: () => void;
}

const CustomAlert: React.FC<CustomAlertProps> = ({ message, onClose }) => {
    return (
        <div style={styles.modal}>
            <div style={styles.modalContent}>
                <span style={styles.closeBtn} onClick={onClose}>&times;</span>
                <h4 style={styles.title}>Message from Disaster Risk Management</h4>
                <p>{message}</p>
                <button style={styles.button} onClick={onClose}>OK</button>
            </div>
        </div>
    );
};

const styles = {
    modal: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'fixed' as 'fixed',
        zIndex: 1,
        left: 0,
        top: 0,
        width: '100%',
        height: '100%',
        overflow: 'auto',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
    },
    modalContent: {
        backgroundColor: '#fefefe',
        padding: '20px',
        border: '1px solid #888',
        width: '80%',
        maxWidth: '300px',
        textAlign: 'center' as 'center',
        borderRadius: '10px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        position: 'relative' as 'relative',
    },
    closeBtn: {
        color: '#aaa',
        fontSize: '28px',
        fontWeight: 'bold' as 'bold',
        cursor: 'pointer',
        position: 'absolute' as 'absolute',
        top: '10px',
        right: '20px',
    },
    button: {
        padding: '10px 20px',
        margin: '20px',
        cursor: 'pointer',
        color: "#948888",
        backgroundColor: "#5025a4"
    },
    title: {
        margin: '0 0 20px 0',
    },
};

export default CustomAlert;
