import React, {useEffect, useState, useRef} from 'react';
import {Dialog, DialogTitle, DialogContent, DialogActions, IconButton, Box, Button} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {mapViewRef} from '../../../containers/FloodForecast'; // Ensure the correct path
import {toPng} from 'html-to-image';
import {AppAPIs} from "../../../../Api";
import BoundaryStatsTable from "../BoundaryStatsTable";
import {store} from "../../../../store/store";
import {floodActions} from "../../../../store/slice/flood";
import {useSelector} from "react-redux";
import jsPDF from 'jspdf';

const PdfPreviewDialog = ({open, onClose, mapVM}) => {
    const [screenshot, setScreenshot] = useState('');
    const [statsData, setStatsData] = useState(null);
    const statsTableRef = useRef(null);
    const floodMapId = useSelector((state: any) => state.flood.floodMapId);
    const displayName = useSelector((state: any) => state.flood.displayName);

    useEffect(() => {
        if (open) {
            getStatsData();
            captureScreenshot();
        }
    }, [open]);

    const getStatsData = () => {
        if (floodMapId) {
            mapVM?.getMapLoadingRef()?.current?.openIsLoading();
            mapVM.getApi().get(AppAPIs.FF_AFFECTED_TEHSIL_DETAILS, {map_Id: floodMapId.map_id[0]}).then((payload) => {
                if (payload) {
                    setStatsData(payload);
                }
            }).finally(() => {
                store.dispatch(floodActions.closeDialog());
                mapVM.getMapLoadingRef()?.current?.closeIsLoading();
            });
        }
    }

    const captureScreenshot = () => {
        if (mapViewRef.current) {
            const olMapInstance = mapViewRef.current.getMapVM().getMap();
            const viewportElement = olMapInstance.getViewport();
            if (viewportElement) {
                toPng(viewportElement)
                    .then((dataUrl) => {
                        setScreenshot(dataUrl);
                    })
                    .catch((err) => {
                        console.error('Error capturing screenshot:', err);
                        alert('Unable to capture screenshot due to cross-origin resource issues.');
                    });
            } else {
                console.error('Viewport element is not found or is not an HTMLElement');
            }
        }
    };

    const handleSaveToPdf = () => {
        const doc = new jsPDF('portrait', 'px', 'a4');
        const margin = 20;
        const pageWidth = doc.internal.pageSize.getWidth();
        const pageHeight = doc.internal.pageSize.getHeight();

        const image = new Image();
        image.src = screenshot;

        image.onload = () => {
            const imgWidth = pageWidth - margin * 2;
            const imgHeight = (image.height / image.width) * imgWidth;

            // Adding the map screenshot
            doc.addImage(screenshot, 'PNG', margin, margin, imgWidth, imgHeight);

            // Capture stats table as an image and add to PDF
            if (statsTableRef.current) {
                toPng(statsTableRef.current)
                    .then((dataUrl) => {
                        // Calculate available space after the map screenshot
                        const remainingHeight = pageHeight - (imgHeight + margin * 2 + 20);
                        const statsImgWidth = imgWidth;
                        const statsImgHeight = (statsTableRef.current.clientHeight / statsTableRef.current.clientWidth) * statsImgWidth;

                        // Scale stats table image to fit the remaining space
                        const scale = Math.min(statsImgWidth / statsTableRef.current.clientWidth, remainingHeight / statsImgHeight);
                        const finalWidth = statsTableRef.current.clientWidth * scale;
                        const finalHeight = statsTableRef.current.clientHeight * scale;

                        doc.addImage(dataUrl, 'PNG', margin, imgHeight + margin + 20, finalWidth, finalHeight);
                        doc.save(displayName+'flood_data.pdf');
                    })
                    .catch((err) => {
                        console.error('Error capturing stats table:', err);
                    });
            }
        };
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen
        >
            <DialogTitle sx={{m: 0, p: 2, backgroundColor: "black", color: "white"}}>
                PDF Preview
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[100],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '20px', // 64px accounts for the dialog title height
                        overflow: 'auto',
                    }}>
                    <h3>{displayName} Flood Susceptibility</h3>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '60vh', // 64px accounts for the dialog title height
                        overflow: 'auto',
                    }}
                >
                    {screenshot ? (
                        <img src={screenshot} alt="Map Screenshot" style={{maxWidth: '100%', maxHeight: '100%'}}/>
                    ) : (
                        <div>Loading screenshot...</div>
                    )}
                </Box>
                <Box
                    ref={statsTableRef}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        overflow: 'auto',
                    }}
                >
                    {statsData && <BoundaryStatsTable key={"stats-info"} data={statsData}/>}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSaveToPdf} color="primary">
                    Save to PDF
                </Button>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default PdfPreviewDialog;
