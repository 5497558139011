import React, {useState} from 'react';

const OTPInput = ({length = 6, onChangeOTP}) => {
    const [otp, setOTP] = useState(new Array(length).fill(""));

    const handleChange = (element, index) => {
        if (isNaN(element.value)) return false;
        const newOTP = [...otp];
        newOTP[index] = element.value;
        setOTP(newOTP);
        onChangeOTP(newOTP.join(""));

        // Focus on the next input if the user has entered a digit
        if (element.nextSibling) {
            element.nextSibling.focus();
        }
    };

    return (
        <div>
            {otp.map((data, index) => {

                return (
                    <input key={index} type="text" maxLength={1} value={data}
                           onChange={e => handleChange(e.target, index)} onFocus={e => e.target.select()}
                           style={{width: "40px", marginRight: "10px", textAlign: "center"}}/>
                );
            })}
        </div>
    );
};

export default OTPInput;
