// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.flow-table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
}
.flow-table thead th {
    background-color: #04AA6D;
    color: white;
}

.flow-table th, td {
    border-bottom: 1px solid #ddd !important;
    border: 0px;
    padding: 1px;
    text-align: center;
}
.flow-table tr:nth-child(even) {background-color: #f2f2f2;}
.flow-table tr:hover {background-color: #b2caf0;}

`, "",{"version":3,"sources":["webpack://./src/modules/components/table-style.css"],"names":[],"mappings":"AAAA;IACI,yCAAyC;IACzC,yBAAyB;AAC7B;AACA;IACI,yBAAyB;IACzB,YAAY;AAChB;;AAEA;IACI,wCAAwC;IACxC,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA,gCAAgC,yBAAyB,CAAC;AAC1D,sBAAsB,yBAAyB,CAAC","sourcesContent":[".flow-table {\n    font-family: Arial, Helvetica, sans-serif;\n    border-collapse: collapse;\n}\n.flow-table thead th {\n    background-color: #04AA6D;\n    color: white;\n}\n\n.flow-table th, td {\n    border-bottom: 1px solid #ddd !important;\n    border: 0px;\n    padding: 1px;\n    text-align: center;\n}\n.flow-table tr:nth-child(even) {background-color: #f2f2f2;}\n.flow-table tr:hover {background-color: #b2caf0;}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
