import * as React from 'react';
import {
    Box,
    CssBaseline, Divider,
    Drawer,
    IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText,
    Menu,
    MenuItem,
    styled,
    Toolbar,
    Typography,
    useTheme
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import AccountCircle from '@mui/icons-material/AccountCircle';
import LoginIcon from '@mui/icons-material/Login';

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { authActions } from "../../store/slice/auth";
import { appSnackbarRef } from "../../App";
import LoginForm from "../components/base/LoginForm";
import {RootState} from "../../store/store";
import ContentRouter from "../../ContentRouter";

const drawerWidth = 240;
const toolbarHeight = 64;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    display: "flex",
    height: `calc(100vh - ${toolbarHeight}px)`,
    // backgroundColor: theme.palette.background.default,
    backgroundColor: "#aaaaaa",
    overflow: "auto",
    boxSizing: "border-box",
    marginTop: toolbarHeight + "px",
    padding: theme.spacing(2),
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

export const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

export default function BaseContainer() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState<boolean>(false);
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const auth = useSelector((state: RootState) => state.auth.isAuthenticated);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };

    const drawerMenuItems = ['Home', 'Flood', 'Heat Analysis', 'Drought'] as const;

    const handleNavigation = (text: typeof drawerMenuItems[number]) => {
        switch (text) {
            case 'Home':
                navigate("/");
                break;
            case 'Flood':
                navigate("/ff");
                break;
            case 'Heat Analysis':
                navigate("/heat_analysis");
                break;
            case 'Drought':
                navigate("/drought");
                break;
        }
    };

    const closeAnchorEl = () => {
        setAnchorEl(null);
    };

    const handleLoginMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleManageLayer = () => {
        navigate("/manage/layer_info/");
        setAnchorEl(null);
    };

    const handleManageMap = () => {
        navigate("/manage/map_info/");
        setAnchorEl(null);
    };

    const handleRiskAssessment = () =>{
        navigate("/manage/risk_assessment/")
        setAnchorEl(null)
    }

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        setAnchorEl(null);
        dispatch(authActions.logout(null));
        appSnackbarRef?.current?.show("Thank you for visiting site");
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" open={open}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2, ...(open && { display: 'none' }) }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
                        Disaster Risk Management
                    </Typography>
                    <div>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleLoginMenu}
                            color="inherit"
                        >
                            {auth ? <AccountCircle /> : <LoginIcon />}
                        </IconButton>
                        {auth ? (
                            <Menu
                                key="main-menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleMenuClose}
                            >
                                <MenuItem key="auth-manage-layer" onClick={handleManageLayer}>Manage Layer</MenuItem>
                                <MenuItem key="auth-manage-map" onClick={handleManageMap}>Manage Map</MenuItem>
                                <MenuItem key="manage-risk-assessment" onClick={handleRiskAssessment}>Manage Risk Assessment</MenuItem>
                                <MenuItem key="auth-log-out" onClick={handleLogout}>Log out</MenuItem>
                            </Menu>
                        ) : (
                            <Menu
                                key="login-form-menu"
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={handleMenuClose}
                            >
                                <LoginForm key="login-form" closeDropDown={closeAnchorEl} />
                            </Menu>
                        )}
                    </div>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="persistent"
                anchor="left"
                open={open}
            >
                <DrawerHeader>
                    <IconButton onClick={handleDrawerClose}>
                        {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    {drawerMenuItems.map((text, index) => (
                        <ListItem key={text} disablePadding>
                            <ListItemButton onClick={() => handleNavigation(text)}>
                                <ListItemIcon>
                                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
            <Main open={open}>
                <DrawerHeader />
                <ContentRouter />
            </Main>
        </Box>
    );
}
