import {IFeatureStyle} from "damap/dist/esm/ol-map/TypeDeclaration";
import MapVM from "damap/dist/esm/ol-map/models/MapVM";
import OverlayVectorLayer from "damap/dist/esm/ol-map/layers/overlay_layers/OverlayVectorLayer";
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle as CircleStyle, Fill, Stroke, Style } from 'ol/style';

import {AppAPIs} from "../../Api";
import {store} from "../../store/store";


class DrmViewModel {
    static getVectorLayerByTitle(title: string, mapVM:MapVM) : VectorLayer<any> {
        const map = mapVM.getMap();
        // @ts-ignore
        const layers: VectorLayer<any>[] = map.getLayers().getArray().filter(layer => layer instanceof VectorLayer) ;
        for (let i = 0; i < layers.length; i++) {
            if (layers[i].get('title') === title) {
                return layers[i];
            }
        }
        return null;
    }

    static createMarkLocationLayer(mapVM: MapVM){
        const layerTitle =  MapVM.generateUUID();
        // Define a style for the marker
        const markerStyle = new Style({
            image: new CircleStyle({
                radius: 10,
                fill: new Fill({ color: 'green' }),
                stroke: new Stroke({
                    color: [255, 255, 255], width: 3
                })
            })
        });

        // Create a vector source and layer to hold the marker
        const vectorSource = new VectorSource();
        const vectorLayer = new VectorLayer({
            //@ts-ignore
            title: layerTitle,
            displayInLayerSwitcher: false,
            source: vectorSource,
            style: markerStyle  // Set the style for the layer
        });
        mapVM.getMap().addLayer(vectorLayer);
        return layerTitle
    }
    static createFloodLayer(mapVM: MapVM, uuid: string, is_fluvial = false): OverlayVectorLayer {
        const title = is_fluvial ? "fluvial flood Layer" : " Flood layer"
        const style: IFeatureStyle = {
            type: "single",
            style: {
                default: {
                    strokeColor: "#b21e42",
                    strokeWidth: 2,
                    fillColor: "rgba(168,168,227,0.6)"
                }
            }
        }
        const info = {
            uuid: uuid,
            title: title,
            style: style
        }
        return new OverlayVectorLayer(info, mapVM)

    }

    static createSettlementLayer(mapVM: MapVM): OverlayVectorLayer {
        const style: IFeatureStyle = {
            type: "single",
            style: {
                default: {
                    "pointSize": 15,
                    "pointShape": "circle",
                    "strokeColor": "#000000",
                    "strokeWidth": 3,
                    "fillColor": "#d78544"
                }
            }
        }
        const info = {
            uuid: MapVM.generateUUID(),
            title: "flood layer",
            style: style
        }
        return new OverlayVectorLayer(info, mapVM)
    }

    static handleStatsDetails(mapVM: MapVM) {
        // console.log("Stats detail working....")

        // const geojson = floodLayer.toGeoJson()
        const api = mapVM.getApi()
        mapVM.getMapLoadingRef().current?.openIsLoading();
        const mapId = store.getState().flood.floodMapId
        console.log("mapid", mapId)
        api.get(AppAPIs.FF_AFFECTED_POP, {map_id: mapId.map_id[0]}).then((payload) => {
            if (payload) {
                const settlementLayer = DrmViewModel.createSettlementLayer(mapVM)
                settlementLayer.addGeojsonFeature(payload)
                mapVM.setLayerOfInterest(settlementLayer.layerInfo.uuid);
                setTimeout(() => {
                    mapVM?.openAttributeTable()
                    settlementLayer.zoomToFeatures()
                }, 1000);

            }
            mapVM.getMapLoadingRef().current?.closeIsLoading()
        }).catch(()=>{
            alert("No settlements found")
        }).finally(()=>{
            mapVM.getMapLoadingRef().current?.closeIsLoading();
        })
    }

}

export default DrmViewModel
