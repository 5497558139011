import React, {useEffect, useRef, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField} from '@mui/material';
import {getArea} from 'ol/sphere';
import {Draw} from 'ol/interaction';
import {Vector as VectorLayer} from 'ol/layer';
import {Vector as VectorSource} from 'ol/source';
import {Style, Fill, Stroke} from 'ol/style';
import {Polygon} from 'ol/geom';
import {Feature} from 'ol';
import GeoJSON from 'ol/format/GeoJSON';
import {AppAPIs} from "../../../Api";
import OTPDialog, {OTPDialogHandle} from "./OTPDialog";

interface AOIComponentProps {
    mapVM: any; // Replace 'any' with the specific type of mapVM if you have it
}

const RiskAssessmentRequest: React.FC<AOIComponentProps> = ({mapVM}) => {
    const [isDrawing, setIsDrawing] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const aoiFeatureRef = useRef<Feature<Polygon> | null>(null);
    const otpDialogRef = useRef<OTPDialogHandle>();

    // Use refs to store instances of vectorSource, vectorLayer, and drawInteraction
    const vectorSourceRef = useRef<VectorSource>(new VectorSource());
    const vectorLayerRef = useRef<VectorLayer<VectorSource>>();
    const drawInteractionRef = useRef<Draw | null>(null);

    useEffect(() => {
        const olMap = mapVM.getMap();

        // Create the vector layer only once
        vectorLayerRef.current = new VectorLayer({
            // @ts-ignore
            title: 'AOI',
            source: vectorSourceRef.current,
            style: new Style({
                fill: new Fill({
                    color: 'rgba(255, 255, 255, 0.2)',
                }),
                stroke: new Stroke({
                    color: '#ffcc33',
                    width: 2,
                }),
            }),
        });

        // Add the layer to the map
        olMap.addLayer(vectorLayerRef.current);

        // Create the draw interaction
        drawInteractionRef.current = new Draw({
            source: vectorSourceRef.current,
            type: 'Polygon',
        });

        // Add the drawend event listener
        drawInteractionRef.current.on('drawend', (event) => {
            const polygon = event.feature.getGeometry() as Polygon;
            const areaInSquareMeters = getArea(polygon); // Calculate the area in square meters
            const areaInSquareKm = areaInSquareMeters / 1e6; // Convert to square kilometers

            if (areaInSquareKm > 4000) {
                alert(`The area of the polygon is more than 4000 square kilometers. Current area is ${areaInSquareKm.toFixed(2)}. Please create a smaller polygon.`);

                // Zoom in to the polygon's extent
                const extent = polygon.getExtent();
                olMap.getView().fit(extent, {maxZoom: 10}); // Adjust zoom level to fit the polygon

                // Clear the drawn polygon and reset references
                vectorSourceRef.current.clear(); // Clears the polygon from the map
                aoiFeatureRef.current = null; // Clear the reference
                olMap.render(); // Force the map to refresh
            } else {
                // Save the drawn feature and disable drawing
                aoiFeatureRef.current = event.feature as Feature<Polygon>;
                setIsDrawing(false);
                olMap.removeInteraction(drawInteractionRef.current as Draw);
            }
        });

        return () => {
            // Cleanup: remove the layer and interaction when component unmounts
            olMap.removeLayer(vectorLayerRef.current as VectorLayer<VectorSource>);
            if (drawInteractionRef.current) {
                olMap.removeInteraction(drawInteractionRef.current);
            }
        };
    }, [mapVM]);

    const handleMarkAOI = () => {
        const olMap = mapVM.getMap();
        if (!isDrawing) {
            // Clear the previous AOI from the vector source
            vectorSourceRef.current.clear(); // This clears all previous features from the source
            aoiFeatureRef.current = null; // Clear the reference to the AOI

            // Add the drawing interaction
            olMap.addInteraction(drawInteractionRef.current as Draw);
        } else {
            olMap.removeInteraction(drawInteractionRef.current as Draw); // Disable drawing
        }
        setIsDrawing(!isDrawing); // Toggle drawing state
    };

    const handleGenerateReport = () => {
        if (aoiFeatureRef.current) {
            setOpenDialog(true); // Open the dialog box
        } else {
            alert('Please mark an Area of Interest (AOI) first.');
        }
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSubmit = () => {
        if (aoiFeatureRef.current && phoneNumber) {
            const feature = aoiFeatureRef.current;

            // Convert the single feature to GeoJSON
            const geoJSON = new GeoJSON().writeFeatureObject(feature, {
                featureProjection: 'EPSG:3857', // Assuming your map is in 3857
                dataProjection: 'EPSG:4326', // Convert to standard GeoJSON projection
            });

            const api = mapVM.getApi();

            // Make sure the geoJSON is passed as an object and not a string
            api.post(AppAPIs.DRM_HAZARD_REQUEST, geoJSON, {
                phone_no: phoneNumber,
                request_type: "flood_forecast"
            }).then((payload) => {
                if (payload) {
                    if (payload.request_id) {
                        otpDialogRef?.current && otpDialogRef?.current?.openDialog(payload.request_id);
                    }
                } else {
                    alert('Failed to submit the request.');
                }
            }).catch((error) => {
                console.error("Error submitting request:", error);
                alert('An error occurred while submitting the request.');
            }).finally(() => {
                setOpenDialog(false); // Close the dialog after submission
            });
        } else {
            alert('No AOI feature available or phone number is missing.');
        }
    };


    return (
        <Box padding={2}>
            <Button
                variant="contained"
                color={isDrawing ? "secondary" : "primary"}
                onClick={handleMarkAOI}
                fullWidth
                style={{marginBottom: '10px'}}
            >
                {isDrawing ? "Finish Marking AOI" : "Mark AOI"}
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={handleGenerateReport}
                fullWidth
            >
                Generate Report
            </Button>

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Enter Your Phone Number</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Phone Number"
                        value={phoneNumber}
                        onChange={(e) => setPhoneNumber(e.target.value)}
                        fullWidth
                        margin="dense"
                    />
                    <Box marginTop={2}>
                        <p>You will receive a message after the report is processed.</p>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
            <OTPDialog ref={otpDialogRef} mapVM={mapVM}/>
        </Box>
    );
};

export default RiskAssessmentRequest;
