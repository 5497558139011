import * as React from "react";
import {useLocation} from "react-router-dom";
import {store} from "./store/store";
import {Provider} from "react-redux";
import "./App.css";
import {createTheme, ThemeProvider} from "@mui/material";
import BaseContainer from "./modules/containers/BaseContainer";
import {RefObject, useState} from "react";
import DASnackbar from "damap/dist/esm/ol-map/components/common/DASnackbar";
import ContentRouter from "./ContentRouter";
import LoadingComponent from "./modules/components/LoadingComponent";
import {authActions} from "./store/slice/auth";
import CustomAlert from "./modules/components/base/CustomAlert";


const theme = createTheme({
    // status: {danger: orange[500],},
    // @ts-ignore
    palette: {
        primary: {
            main: '#2FAB16' //'#234184'
        },
        secondary: {
            main: "#8f6446" //"#bf4343",
        },
    },
});
//#3f5346
export const appSnackbarRef: RefObject<DASnackbar> = React.createRef()
export const loadingRef: RefObject<LoadingComponent> = React.createRef()

function App() {
    const location = useLocation();
    const isMainRoute = location.pathname === '/';
    const [alertMessage, setAlertMessage] = useState<string | null>(null);

    const handleCloseAlert = () => {
        setAlertMessage(null);
    };
    React.useEffect(() => {

        store.dispatch(authActions.login(null))
        const originalAlert = window.alert;

        window.alert = (message: string) => {
            setAlertMessage(message);
        };

        return () => {
            window.alert = originalAlert; // Clean up: Restore the original alert function on unmount
        };
    }, []);

    return (
        <React.Fragment>
            <Provider store={store}>
                <ThemeProvider theme={theme}>

                    {isMainRoute ? <ContentRouter/> : <BaseContainer/>}
                    <DASnackbar ref={appSnackbarRef}/>
                    <LoadingComponent ref={loadingRef} />
                </ThemeProvider>
            </Provider>
            {alertMessage && <CustomAlert message={alertMessage} onClose={handleCloseAlert}/>}

        </React.Fragment>
    );
}

export default App;
