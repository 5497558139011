import React, {RefObject} from "react"
// import DASnackbar from "damap/dist/esm/ol-map/components/common/DASnackbar";
import {appSnackbarRef} from "../../../App";
import ChangeList, {Action} from "damap/dist/esm/admin/components/ChangeList";
import {useNavigate} from "react-router-dom";
import {Column, Row} from "damap/dist/esm/widgets/grid/GridTypeDeclaration";
import MapApi, {MapAPIs} from "damap/dist/esm/ol-map/utils/MapApi";
import JqxGrid from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgrid";
import {AppAPIs} from "../../../Api";

const changeListRef = React.createRef<ChangeList>();

const ManageRiskAssessment = () => {
    const [columns, setColumns] = React.useState<Column[]>([]);
    const [data, setData] = React.useState<Row[]>();
    const [actions, setActions] = React.useState<Action[]>([]);
    const api = React.useMemo(() => new MapApi(appSnackbarRef), []);
    const [gridInstance, setGridInstance] = React.useState<JqxGrid>(null);

    const navigate = useNavigate();


    React.useEffect(() => {
        if (changeListRef.current && !gridInstance) {
            const instance = changeListRef.current.getJqxGridRef().current;
            if (instance) {
                // Store grid instance in state
                setGridInstance(instance);
            }
        }
    }, [gridInstance]);

    const getRowData = React.useCallback(() => {
        return changeListRef.current?.getSelectedRowData();
    }, []);
    const getSelectedRequestID = React.useCallback(() => {
        const rowData = getRowData();
        if (rowData) {
            return rowData.id;
        }
    }, [getRowData]);
    const initAction = React.useCallback(() => {
        const actions = [
            {
                name: "View Map",
                action: () => {
                    const requestId = getSelectedRequestID();
                    console.log("request_id", requestId)
                    if (requestId) {
                        navigate("/rar/" + requestId);
                    } else {
                        // appSnackbarRef.current?.show("Please select a row");
                    }
                },
            },
            {
                name: "Process Request",
                action: () => {
                    const requestId = getSelectedRequestID();
                    if (requestId) {
                        api.get(AppAPIs.DRM_EXECUTE_HAZARD_REQUEST, {request_id: requestId}).then((payload) => {
                            if (payload) {
                                appSnackbarRef?.current?.show("The request is under process")
                            }
                        });
                    }else {
                        appSnackbarRef.current?.show("Please select a row");
                    }
                }
            },{
                name: "Send Response SMS",
                action: () => {
                    const requestId = getSelectedRequestID();
                    if (requestId) {
                        api.get(AppAPIs.DRM_SEND_RESPONSE_SMS, {request_id: requestId}).then((payload) => {
                            if (payload) {
                                appSnackbarRef?.current?.show("sms send")
                            }
                        });
                    }else {
                        appSnackbarRef.current?.show("Please select a row");
                    }
                }
            }, {
                name: "Delete Request",
                action: () => {
                    const requestId = getSelectedRequestID();
                    console.log("requestId", requestId)
                    if (requestId) {
                        api.get(AppAPIs.MANAGE_DELETE_HAZARD_MODELING_REQUEST, {request_id: requestId}).then((payload) => {
                            if (payload) {
                                window.location.reload();
                                setTimeout(() => {
                                    appSnackbarRef.current?.show("Map Info deleted successfully");
                                }, 3000);
                            }
                        });
                    } else {
                        appSnackbarRef.current?.show("Please select a row");
                    }
                },
            },
        ];
        setActions(actions);
    }, [navigate, api, getSelectedRequestID]);
    React.useEffect(() => {
        initAction();
        api.get(AppAPIs.MANAGE_DRM_ALL_RISK_ASSESSMENT).then((payload) => {
            if (payload) {
                setColumns(payload.columns);
                setData(payload.rows);
            }
        });
    }, [api, initAction]);

    React.useEffect(() => {
        if (changeListRef.current) {
            // const gridInstance = changeListRef.current.getJqxGridRef().current;
            if (gridInstance) {

                gridInstance.setOptions({
                    // filtermode: 'excel',
                    showfilterrow: true,
                });


            }
        }
    }, [gridInstance, columns, data]);

    return (
        <React.Fragment>

            {columns.length > 0 && (
                //@ts-ignore
                <ChangeList
                    ref={changeListRef}
                    columns={columns}
                    data={data || []}
                    tableHeight={"100%"}
                    tableWidth={"100%"}
                    actions={actions}
                    api={api}
                    modelName={"MapInfo"}
                    pkColName={"uuid"}
                />
            )}

        </React.Fragment>
    )
}
export default ManageRiskAssessment