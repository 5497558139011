import React, { PureComponent} from 'react';
import { Backdrop, CircularProgress } from '@mui/material';

interface IProps{

}
interface IState{
    loading: boolean
}
class LoadingComponent extends PureComponent<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    openLoader = () => {
        this.setState({ loading: true });
    };

    closeLoader = () => {
        this.setState({ loading: false });
    };

    render() {
        const { loading } = this.state;

        return (
            <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, color: '#fff' }} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        );
    }
}

export default LoadingComponent;
